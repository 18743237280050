@import '@/assets/scss/_variables.scss';
.date-picker-input {
  //border: 1px solid transparent;
  // background: rgba(255, 255, 255, 0.04);
  box-shadow: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.text-field-input-root {
  width: 100%;

  .v-label {
    font-size: 14px;

    @media only screen and (min-width: $tablet-width) {
      font-size: 16px;
    }
  }

  .theme--dark.v-input--is-disabled input {
    color: #fff;
  }

  .TextFieldInput_label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--bold {
      label {
        font-weight: 500;
      }
    }

    &--large {
      label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .v-input.v-input--dense.v-text-field {
    width: 100%;

    .v-input__control {
      min-height: 46px;

      .v-input__slot {
        border: 1px solid transparent;
        background: rgba(#fff, 0.04); // change color bg input
        box-shadow: none;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;

        .v-text-field__slot {
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
            -webkit-transition-delay: 9999s;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input[type='number'] {
            -moz-appearance: textfield; /* Firefox */
          }
        }
      }

      .v-text-field__details {
        padding: 0;

        .v-messages.error--text {
          font-size: 12px;
          line-height: 14px;
          color: #ff556d;

          @media only screen and (min-width: $tablet-width) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    &.error--text {
      .v-input__control {
        .v-input__slot {
          border: 1px solid #ff556d;
          background-color: rgba(255, 85, 109, 0.04);

          .v-input__append-inner {
            .v-input__icon {
              .v-icon {
                color: #ff556d;
              }
            }
          }
        }
      }
    }
  }

  &--hasShadow {
    .v-input.v-input--dense.v-text-field {
      .v-input__control {
        .v-input__slot {
          box-shadow: 0 18px 21px rgba(34, 39, 47, 0.34);
        }
      }
    }
  }

  &--noRightBorder {
    .v-input.v-input--dense.v-text-field {
      .v-input__control {
        .v-input__slot {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

.TextFieldInput_rightLabelString {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.TextFieldInput_rightValueFee {
  float: right;
  margin: 0 0 0 5px;
}

.TextFieldInput_rightLabelValue {
  color: #2af3f3;
  font-size: 14px;
  line-height: 16px;
}

.TextFieldInput_dropdownArrow {
  color: #fff;
  transition: all .25s;

  @media only screen and (min-width: $tablet-width) {
    width: 20px;
    transform-origin: 60% 50%;
  }

  &--active {
    transform: rotate(180deg);
  }
}